.general-button {
  padding: 1rem 3.5rem;
  margin-top: 2rem;
  border: 0;
}

.general-button-primary {
  background-color: #ff007b;
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin-right: 1rem;
  border: 1px solid #ff007b;
  text-transform: uppercase;
}

.general-button-secondary {
  background-color: #ffffff;
  color: #ff007b;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin-right: 1rem;
  text-transform: uppercase;
  border: 1px solid #ff007b;
}
