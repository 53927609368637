.md-floating-label {
  color: black;
}

#img-login {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
  margin-top: 40px;
  max-width: 100px;
}

.navbar-login {
  overflow: hidden;
  background-color: black;
  position: fixed;
  bottom: 0;
  height: 50px;
  left: 0;
  width: 100%;
}

.navbar-login p {
  justify-content: center;
  color: #ffffff;
}
.md-cell {
  margin: 0 auto !important;
  width: 100% !important;
}

.md-text-field:-webkit-autofill,
.md-text-field:-webkit-autofill:hover,
.md-text-field:-webkit-autofill:focus,
.md-text-field:-webkit-autofill:active {
  transition: "color 9999s ease-out, background-color 9999s ease-out";
  transition-delay: 9999s;
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
  background-color: transparent;
}

.md-text {
  color: black !important
}

.button-login {
  background: $green-primary-fermento;
  color: white;
  border-radius: 50px !important;
}

.button-login:hover {
  background: $green-secondary-fermento;
  color: white;
}

.button-login:focus,
.button-login:active {
  background: $blue-secondary-fermento;
  color: white;
}

.md-background--primary {
  background: white;
}

.md-title {
  color: black !important;
}
.container-login {
  height: 100vh;
  width: 100vw;
   background: white;
  }

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;
  width: 300px;
  padding: 20px;
  height: 300px;
}

.signup {
  position: relative;
  top: 30%;
  left: 50%;
  margin: -150px 0 0 -150px;
  width: 300px;
  padding: 20px;
}

.login {
  h1 {
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    letter-spacing: 1px;
    text-align: center;
  }
}

.md-paper--2 {
  -webkit-box-shadow: none;
  box-shadow: none;
}
