* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  overflow: auto;
}

body,
html {
  font-size: 100%;
}

button {
  outline: none !important;
}
.row.custom-gutter {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

[class^="col"].custom-gutter,
[class*=" col"].custom-gutter {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.MuiTableCell-paddingCheckbox:last-child {
  background: #ababab;
  color: white;
}
.content {
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
/*
main {
  background-color: #f7f7f7;
}

body {
  background-color: #f7f7f7;
} */

button {
  border: 0;
  padding: 0;
  background: inherit;
  font-family: "Roboto";
}

.form-primary {
  border: 1px solid #ff007b;
  font-family: "Roboto";
  font-size: 16px;
  border-radius: 0;
  padding: 19px 10px;
  font-weight: 400;
}


.invalid-feedback-addition {
  position: absolute;
}

.form-group-addition {
  margin-bottom: 1.5rem;
}

.font-dosis {
  font: 16px OpenSans, Arial, Helvetica, sans-serif;
}

a:hover {
  text-decoration: none;
}


